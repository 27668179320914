//

//! Import

import {
     //* Local
     // data
     movies, variables,
     // dupes
     sendFeedback,

     //* Firebase
     // firebase authentication
     auth, onAuthStateChanged, signOut,
     // firebase firestore
     firestore, collection, doc, setDoc, getDoc, updateDoc
} from "./zImports.js"

const inBetween = variables.inBetween
const moviesToShow = variables.moviesToShow
const maxCharactersPerLine = variables.maxCharactersPerLine
const maxCharactersPerTitle = variables.maxCharactersPerTitle


//! Render Page

//* Language

let currentUrl = window.location.href
let url = new URL(currentUrl)
let language = url.searchParams.get("lang")


//! Hover Effects

//* Feedback

const feedbackImgQ = document.getElementById('feedbackImgQ')
const feedbackHoverImgQ = document.getElementById('feedbackHoverImgQ')
const feedbackButtonQ = document.getElementById('feedbackButtonQ')

feedbackButtonQ.addEventListener('mouseover', (event) => {
     feedbackImgQ.classList.add("hidden")
     feedbackHoverImgQ.classList.remove("hidden")
})

feedbackButtonQ.addEventListener('mouseout', (event) => {
     feedbackImgQ.classList.remove("hidden")
     feedbackHoverImgQ.classList.add("hidden")
})


//* Patch notes

const versionSpanQ = document.getElementById('versionSpanQ')
const patchNotesImgQ = document.getElementById('patchNotesImgQ')
const patchNotesHoverImgQ = document.getElementById('patchNotesHoverImgQ')

versionSpanQ.addEventListener('mouseover', (event) => {
     patchNotesImgQ.classList.add("hidden")
     patchNotesHoverImgQ.classList.remove("hidden")
})

versionSpanQ.addEventListener('mouseout', (event) => {
     patchNotesImgQ.classList.remove("hidden")
     patchNotesHoverImgQ.classList.add("hidden")
})


//! Start UI

let starLockArray = []

for(let i = 1; i <= 100; i++) {
     let starDiv = document.getElementById(`starDiv${i}Q`)
     const startOfRange = Math.floor((i - 1) / 10) * 10 + 1
     const endOfRange = startOfRange + 9

     //* Add hover effects
     const handleMouseOver = (event) => {
          for(let j = startOfRange; j <= i; j++) {
               let starImg = document.getElementById(`starImg${j}Q`)
               let starImgHover = document.getElementById(`starImgHover${j}Q`)
     
               starImg.classList.add("hidden")
               starImgHover.classList.remove("hidden")
          }
     }
     const handleMouseOut = (event) => {
          for(let j = startOfRange; j <= i; j++) {
               let starImg = document.getElementById(`starImg${j}Q`)
               let starImgHover = document.getElementById(`starImgHover${j}Q`)

               let savedRow = localStorage.getItem('starRow' + Math.floor(((j - 1) / 10) + 1))

               if(savedRow) {
                    let savedStars = parseInt(savedRow)
                    // color marked stars
                    for(let k = startOfRange; k <= savedStars; k++) {
                         let starImg = document.getElementById(`starImg${k}Q`)
                         let starImgHover = document.getElementById(`starImgHover${k}Q`)

                         starImg.classList.add("hidden")
                         starImgHover.classList.remove("hidden")
                    }
                    // uncolor the rest
                    for(let k = savedStars + 1; k <= endOfRange; k++) {
                         let starImg = document.getElementById(`starImg${k}Q`)
                         let starImgHover = document.getElementById(`starImgHover${k}Q`)

                         starImg.classList.remove("hidden")
                         starImgHover.classList.add("hidden")
                    }
               }
               else {
                    starImg.classList.remove("hidden")
                    starImgHover.classList.add("hidden")
               }
          }
     }

     //* Add starlock
     const starLock = (event) => {
          let starNumber = parseInt(starDiv.id.slice(7).slice(0, -1)) // starDiv21Q -> 21
          starLockArray.push(starNumber)
          localStorage.setItem('starRow' + Math.floor(((starNumber - 1) / 10) + 1), starLockArray[0])

          // delete saved numbers
          setTimeout(() => {
               starLockArray = []
          }, 100)
     }

     starDiv.addEventListener('mouseover', handleMouseOver)
     starDiv.addEventListener('mouseout', handleMouseOut)
     starDiv.addEventListener('click', starLock)
}


//! Search Bar

const searchInputQ = document.getElementById('searchInputQ')
const searchedMoviesQ = document.getElementById('searchedMoviesQ')

let shownMovies = []


//* Filtering movies (search bar)

//todo/ this should work only ifcertain things are checked, also add more categories 
//todo/ that work, when different things are checked - eg: It will spit out sorting by rating, year,...
searchInputQ.addEventListener("input", (e) => {
     const searchInputValue = e.target.value.toLowerCase()
     let shown = []

     if(searchInputValue === "") {
          shownMovies = []
          refreshSearch()
          return
     }

     movies.forEach((movie) => {
          if( movie.title.toLowerCase().includes(searchInputValue) ||
               movie.titleCZ.toLowerCase().includes(searchInputValue)) {
               shown.push(movie)
          }
          else if(movie.actors) {
               if(movie.actors.slice(0, 3).some((actor) => actor.toLowerCase().includes(searchInputValue))) {
                    shown.push(movie)
               }
          }
          if(shown.length >= moviesToShow) {
               return
          }
     })
     shownMovies = shown.slice(0, moviesToShow)
     refreshSearch()
})

searchInputQ.addEventListener("click", () => {
     clickProfile(searchInputQ)
})


//* Options
/*
// variables
const optionsButtonQ = document.getElementById('optionsButtonQ')
const searchOptionsQ = document.getElementById('searchOptionsQ')
const option1Radio = document.querySelector('input[name="option"][value="option1"]')

// open options menu
optionsButtonQ.addEventListener('click', () => {
     searchOptionsQ.classList.toggle("hidden")
})

// default option
option1Radio.checked = true

const selectedOption = document.querySelector('input[name="option"]:checked')

function options() {
     if(selectedOption) {
          const selectedValue = selectedOption.value
          console.log('Selected value:', selectedValue)
          
          if(selectedValue === 'option1') {
          }
          else if(selectedValue === 'option2') {
          } 
          else if(selectedValue === 'option3') {
          }
     }
}*/


//* Create li for each movie

refreshSearch()

function refreshSearch() {
     searchedMoviesQ.innerHTML = ""

     shownMovies.forEach((movie, index) => {
          let movieTitle
          if(language === "CZ"){movieTitle = movie.titleCZ}
          else {movieTitle = movie.title}
          let movieTitleURL = encodeURIComponent(movie.title.replace(/ /g, "_") + movie.year)
          let stars = []
          let genres

          if(movie.actors === undefined) {
               stars.push("")
          }
          else {
               for (let i = 0; i < 3; i++) {
                    if(movie.actors[i]) {
                         stars.push(movie.actors[i])
                    }
               }
          }
          if(movie.genres) {genres = movie.genres.join(inBetween)}
          else {genres = ""}

          // only so many characters per line
          let totalMovieTitleLength = movieTitle.length
          let totalStarsLength = stars.join(inBetween).length
          let totalGenresLength = genres.length

          while (totalStarsLength > maxCharactersPerLine && stars.length > 2) {
               stars.pop()
               totalStarsLength = stars.join(inBetween).length
          }
          while (totalGenresLength > maxCharactersPerLine && movie.genres.length > 2) {
               movie.genres.pop()
               totalGenresLength = genres.length
          }
          if(totalMovieTitleLength > maxCharactersPerTitle) {
               if(movie.episode) {
                    movieTitle = movieTitle.split(":")[0]
                    movieTitle = movieTitle + " " + movie.episode
               }
               else {
                    movieTitle = movieTitle.slice(0, maxCharactersPerTitle)
                    movieTitle.lastIndexOf(" ")
                    movieTitle = movieTitle.slice(0, movieTitle.lastIndexOf(" ")) + " ..."
               }
          }

          // create li for each movie
          const movieLi = document.createElement("li")
          movieLi.classList.add("search__li")
          movieLi.innerHTML = `
               <span class="search__span focus" id="searchSpan${index + 1}Q" tabindex="0">
                    <a class="search__a" href="html/movie.html?movie=${movieTitleURL}">
                         <h3 class="search__h3">${movieTitle}</h3>
                         <p class="search__p search__p-genres">${genres}</p>
                         <p class="search__p search__p-stars">${stars.join(inBetween)}</p>
                         <p class="search__p search__p-year">${movie.year}</p>
                    </a>
               </span>`
          searchedMoviesQ.appendChild(movieLi)

          // add movie key to local storage
          const anchorElement = movieLi.querySelector('.search__a')
          anchorElement.addEventListener('click', function () {
               localStorage.setItem('movie', movieTitle)
          })
     })
     addEventListenersToSearchSpans()
}


//! Auth State Watcher

const accountQ = document.getElementById('accountQ')
const profileQ = document.getElementById('profileQ')

let feedbackCol
let userFeedbackDoc
let ratingsCol
let userRatingsDoc

onAuthStateChanged(auth, user => {
     if(user) {
          //* UI related
          accountQ.classList.add("hidden")
          profileQ.classList.remove("hidden")

          //* Firestore related
          // ratings
          ratingsCol = collection(firestore, "ratings")
          userRatingsDoc = doc(ratingsCol, auth.currentUser.uid)

          // feedback
          feedbackCol = collection(firestore, "feedback")
          userFeedbackDoc = doc(feedbackCol, auth.currentUser.uid)
          getDoc(userFeedbackDoc).then((doc) => {
               if(!doc.exists()) {
                    setDoc(userFeedbackDoc, {})
               }
          })
     }
})


//! Feedback Form

// variables
const feedbackSubmitButtonQ = document.getElementById('feedbackSubmitButtonQ')
const feedbackCancelButtonQ = document.getElementById('feedbackCancelButtonQ')
const feedbackQ = document.getElementById('feedbackQ')
const textareaQ = document.getElementById('textareaQ')


//* Open feedback form

feedbackButtonQ.addEventListener("click", () => {
     feedbackQ.classList.remove("hidden")
})


//* Submit feedback

feedbackSubmitButtonQ.addEventListener("click", () => {
     sendFeedback(auth.currentUser.uid).then(() => {}).catch((error) => {console.log(error)})
     feedbackQ.classList.add("hidden")
     textareaQ.value = ""
})


//* Close feedback form

feedbackCancelButtonQ.addEventListener("click", () => {
     feedbackQ.classList.add("hidden")
})


//! Language Switch

//* Variables

const languageQ = document.getElementById('languageQ')
const languagePQ = document.getElementById('languagePQ')
const feedbackButtonPQ = document.getElementById('feedbackButtonPQ')
const profileP1Q = document.getElementById('profileP1Q')
const profileP2Q = document.getElementById('profileP2Q')
const feedbackPQ = document.getElementById('feedbackPQ')
const accountLoginH3Q = document.getElementById('accountLoginH3Q')
const accountSignupH3Q = document.getElementById('accountSignupH3Q')

// index
const rngInnerPQ = document.getElementById('rngInnerPQ')
const rngVersionPQ = document.getElementById('rngVersionPQ')
const primeInnerPQ = document.getElementById('primeInnerPQ')


//* Click flag

languageQ.addEventListener("click", () => {
     if(languagePQ.innerHTML === "🇬🇧") {
          translateToCzech()
     }
     else if(languagePQ.innerHTML === "🇨🇿") {
          translateToEnglish()
     }
})


//* Translate to czech

function translateToCzech() {
     if(!url.searchParams.has('lang')) {
          let newUrl = currentUrl + (currentUrl.includes('?') ? '&' : '?') + "lang=CZ"
          window.history.pushState({ path: newUrl }, '', newUrl)
     }
     searchInputQ.placeholder = "Hledat"
     feedbackButtonPQ.innerHTML = "Zpětná vazba"
     feedbackSubmitButtonQ.innerHTML = "Odeslat"
     feedbackCancelButtonQ.innerHTML = "Zrušit"
     languagePQ.innerHTML = "🇨🇿"
     profileP1Q.innerHTML = "Profil"
     profileP2Q.innerHTML = "Odhlásit se"
     textareaQ.placeholder = "Dejte nám vědět, co si myslíte!"
     feedbackPQ.innerHTML = "Navrhněte filmy, které chybí, designové problémy nebo jakékoliv jiné chyby!"
     accountLoginH3Q.innerHTML = "Přihlásit se"
     accountSignupH3Q.innerHTML = "Registrovat"
     language = "CZ"
     localStorage.setItem('lang', "CZ")
     refreshSearch()

     // index
     rngInnerPQ.innerHTML = "Klikni pro náhodný film"
     primeInnerPQ.innerHTML = "Klikni pro doporučení filmu"
     rngVersionPQ.innerHTML = "model RNG"
     primeVersionPQ.innerHTML = "model Prime"
}


//* Translate to english

function translateToEnglish() {
     url.searchParams.delete("lang")
     window.history.pushState({ path: url.href }, '', url.href)
     
     searchInputQ.placeholder = "Search"
     feedbackButtonPQ.innerHTML = "Share Feedback"
     feedbackSubmitButtonQ.innerHTML = "Submit"
     feedbackCancelButtonQ.innerHTML = "Cancel"
     languagePQ.innerHTML = "🇬🇧"
     profileP1Q.innerHTML = "Profile"
     profileP2Q.innerHTML = "Log out"
     textareaQ.placeholder = "Share your thoughts to help us improve!"
     feedbackPQ.innerHTML = "Suggest movies that are missing, design issues or any other flaws!"
     accountLoginH3Q.innerHTML = "Log in"
     accountSignupH3Q.innerHTML = "Sign up"
     language = "EN"
     localStorage.removeItem('lang')
     refreshSearch()

     // index
     rngInnerPQ.innerHTML = "Click for a random movie"
     primeInnerPQ.innerHTML = "Click for a movie recommendation"
     rngVersionPQ.innerHTML = "model RNG"
     primeVersionPQ.innerHTML = "model Prime"
}


//! Profile

const profileLiQ = document.getElementById('profileLiQ')
const profileDivUlQ = document.getElementById('profileDivUlQ')

profileQ.addEventListener('click', () => {
     clickProfile()
})

function clickProfile(from) {
     if(from == null) {
          if(profileDivUlQ.classList.contains("hidden")) {
               profileDivUlQ.classList.remove("hidden")
               profileDivUlQ.classList.add("profile__div-animation")
          }
          else {
               profileDivUlQ.classList.remove("profile__div-animation")
               profileDivUlQ.classList.add("hidden")
          }
     }
     else if(from === searchInputQ) {
          profileDivUlQ.classList.add("hidden")
          profileDivUlQ.classList.remove("profile__div-animation")
     }
}


//* Log out

const logout = async () => {
     await signOut(auth)
     accountQ.classList.remove("hidden")
     profileQ.classList.add("hidden")
}

const logoutButtonQ = document.getElementById('logoutButtonQ')
logoutButtonQ.addEventListener("click", logout)


//! RNG Model

const rngModelQ = document.getElementById('rngModelQ')
const rngOutputQ = document.getElementById('rngOutputQ')
const rngOutputAQ = document.getElementById('rngOutputAQ')
const rngOutputSpanQ = document.getElementById('rngOutputSpanQ')

rngModelQ.addEventListener('click', rngRecommend)


//* Rng function

function rngRecommend() {
     rngOutputQ.innerHTML = ""
     const randomIndex = Math.floor(Math.random() * movies.length)
     let randomMovie = ""
     
     if(language === "CZ") {
          randomMovie = movies[randomIndex].titleCZ
     }
     else {
          randomMovie = movies[randomIndex].title
     }
     rngOutputQ.innerHTML = randomMovie
     rngOutputAQ.href = "html/movie.html?movie=" + encodeURIComponent(movies[randomIndex].title.replace(/ /g, "_")) + movies[randomIndex].year
}


//! Prime Model

const primeModelQ = document.getElementById('primeModelQ')
const primeOutputQ = document.getElementById('primeOutputQ')

primeModelQ.addEventListener('click', () => {
     predictPrime()
})


//* Prime function

function predictPrime() {
     if(language === "CZ") {
          primeOutputQ.innerHTML = "Již brzy!"
     }
     else {
          primeOutputQ.innerHTML = "Comming soon!"
     }
}


//! Start UI

//* Navigation buttons

const startConfirmButtonQ = document.getElementById('startConfirmButtonQ')
const startCloseButtonQ = document.getElementById('startCloseButtonQ')

startCloseButtonQ.addEventListener('click', () => {
     startQ.classList.add('hidden')
})

startConfirmButtonQ.addEventListener('click', saveRating)


//! Save Ratings To The Database

async function saveRating() {
     let startRatingObject = {}
     let totalRatings = 0

     for(let i = 1; i <= 10; i++) {
          let currentMovie

          // * Get movie rating
          let movieRating = parseInt(localStorage.getItem(`starRow${i}`), 10)
          movieRating = movieRating % 10
          if(movieRating === 0) {
               movieRating = 10
          }
     
          if(movieRating) {
               currentMovie = document.getElementById(`startP${i}Q`).innerText
               startRatingObject[currentMovie] = movieRating
               totalRatings += 1

               //* Find current index of movie
               let x
               movies.some((movie, index) => {
                    let getMovie = movie.title.toLowerCase().replace(/_/g, " ")
                    currentMovie = currentMovie.toLowerCase().replace(/_/g, " ")
                    
                    if(getMovie === currentMovie) {
                         x = index
                         return
                    }
               })
               let movieDatabaseKey = currentMovie + "_" + movies[x].year

               //* Date
               let today = new Date()
               let year = today.getUTCFullYear()
               let month = today.getUTCMonth() + 1 // 0 indexed
               let day = today.getUTCDate()

               //* Number of ratings
               let numberOfRatings
               const docSnap = await getDoc(userRatingsDoc)
               if(docSnap.data().numberOfRatings !== undefined) {
                    if(docSnap.data()[movieDatabaseKey] === undefined) {
                         numberOfRatings = docSnap.data().numberOfRatings + 1
                    }
                    else {
                         numberOfRatings = docSnap.data().numberOfRatings
                    }
               }
               else {
                    numberOfRatings = 1
               }
          
               //* Write to database
               const singleRatingDataToWrite = {
                    "numberOfRatings": numberOfRatings,
                    [movieDatabaseKey]: {
                         "rating": movieRating,
                         "date": day + "/" + month + "/" + year,
                    }
               }
               updateDoc(userRatingsDoc, singleRatingDataToWrite)
          }
     }
     if(totalRatings === 0) {
          alert("Please select a rating")
          return
     }

     //* Close start ui
     startQ.classList.add('hidden')
}


//! Enter-Clicks

//* Search bar

function addEventListenersToSearchSpans() {
     const searchSpans = document.querySelectorAll('[id^="searchSpan"]')

     searchSpans.forEach((element) => {
          element.addEventListener("keydown", (e) => {
               if(e.key === "Enter") {
                    const link = element.querySelector('a')
                    window.location.href = link.href
               }
          })
     })
}


//* Language switch

languageQ.addEventListener('keydown', (e) => {
     if(e.key === 'Enter') {
          languageQ.click()
     }
})


//* Profile

profileQ.addEventListener('keydown', (e) => {
     if(e.key === "Enter") {
          profileQ.click()
     }
})

profileLiQ.addEventListener('keydown', (e) => {
     if(e.key === "Enter") {
          window.location.href = "html/user.html"
     }
})


//* Rng model

rngModelQ.addEventListener('keydown', (e) => {
     if(e.key === "Enter") {
          rngModelQ.click()
     }
})


// rng output
rngOutputSpanQ.addEventListener('keydown', (e) => {
     if(e.key === "Enter") {
          rngOutputAQ.click()
     }
})


//* Prime model

primeModelQ.addEventListener('keydown', (e) => {
     if(e.key === "Enter") {
          primeModelQ.click()
     }
})


//* Html only

const logoQ = document.getElementById('logoQ')
const logoAQ = document.getElementById('logoAQ')

const accountButton1Q = document.getElementById('accountButton1Q')
const accountButton2Q = document.getElementById('accountButton2Q')
const accountA1Q = document.getElementById('accountA1Q')
const accountA2Q = document.getElementById('accountA2Q')

const versionAQ = document.getElementById('versionAQ')


// logo
logoQ.addEventListener('keydown', (e) => {
     if(e.key === "Enter") {
          logoAQ.click()
     }
})

// account
accountButton1Q.addEventListener('keydown', (e) => {
     if(e.key === "Enter") {
          accountA1Q.click()
     }
})

accountButton2Q.addEventListener('keydown', (e) => {
     if(e.key === "Enter") {
          accountA2Q.click()
     }
})

// version
versionSpanQ.addEventListener('keydown', (e) => {
     if(e.key === "Enter") {
          versionAQ.click()
     }
})


//! Reset Page

searchInputQ.value = ""
rngOutputQ.innerHTML = ""
primeOutputQ.innerHTML = ""

if(language === "CZ") {
     translateToCzech()
}
else if(localStorage.getItem('lang') === "CZ") {
     translateToCzech()
}

for(let i = 1; i <= 10; i++) {
     let savedRow = localStorage.getItem('starRow' + i)

     if(savedRow) {
          localStorage.removeItem('starRow' + i)
     }
}